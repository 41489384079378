@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
}

.register-btn {
  background-image: linear-gradient(87.65deg,#8d57ff 3.95%,#1100d0 96.05%);
}

.focus-input:focus {
  border: 1px solid #8b5cf6;
}

.dk-sidebar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.saved-model::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.saved-database-modal::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.loading-wave {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background: #3498db;
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}